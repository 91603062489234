import { ICONS } from '@constants/icons';
import { DealProductType } from '@enums/BillingDeal.enum';
import { DealDescriptionItem } from '@interfaces/BillingDeal.interfaces';
import { Button, Icon } from '@portao3-web/ui';
import { ColumnDef } from '@tanstack/react-table';
import { moneyMask } from '@utils/formatMasks';
import { useTranslation } from 'react-i18next';

interface useNewDealColumsProps {
  deleteLineColumns: (item: DealDescriptionItem) => void;
}

export const useNewDealColumns = ({
  deleteLineColumns,
}: useNewDealColumsProps): ColumnDef<DealDescriptionItem>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'identifier',
      header: t('general.description'),
      cell: ({ cell }) => cell.getValue<string>(),
      meta: {
        width: 'auto',
      },
    },
    {
      accessorKey: 'type',
      header: t('general.type'),
      cell: ({ cell }) => {
        const type = cell.getValue<DealProductType>();

        return t(`invoices.dealProductType.${type}`, '');
      },
      meta: {
        width: 'auto',
      },
    },
    {
      accessorKey: 'price',
      header: t('general.value'),
      cell: ({ cell }) => moneyMask(cell.getValue<number>()),
    },
    {
      accessorKey: 'actions',
      header: t('general.actions'),
      cell: ({ row }) => (
        <Button
          type="button"
          variant="tertiary"
          size="small"
          className="w-6"
          onClick={() => deleteLineColumns(row.original)}
        >
          <Icon size="xlarge">
            <i className={ICONS.trash} />
          </Icon>
        </Button>
      ),
    },
  ];
};
