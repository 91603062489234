import { UserLevelEnum } from '@enums/UserEnum';

export enum AppClientProducts {
  PLATFORM = 'PLATFORM',
  BANKING = 'BANKING',
  NOTIFICATION = 'NOTIFICATION',
  SCHEDULING = 'SCHEDULING',
  BILLING = 'BILLING',
  IDENTITY = 'IDENTITY',
}

export enum AppClientStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface FindAppClientsFilters {
  description?: string | null;
  status?: AppClientStatus | null;
  startDate?: string | null;
  endDate?: string | null;
  limit?: number | null;
  sort?: string | null;
  next?: string | null;
}

export interface AppClientOrganization {
  id: string;
  level: UserLevelEnum;
}

export interface AppClient extends Omit<CreateAppClientInput, 'organizations'> {
  id: string;
  external: Record<string, string>;
  clientSecret: string;
  status: AppClientStatus;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  organizations: AppClientOrganization[];
}

export interface CreateAppClientInput {
  description: string;
  clientId: string;
  realm: string;
  organizations: string[];
  createdBy: string;
  products: AppClientProducts[];
}
