import { Icon, Typography } from '..';
import { StepByStepProps } from './StepByStep.interface';
import './StepByStep.styles.scss';

export const StepByStep = ({ steps, stepClick }: StepByStepProps) => {
  const StateComplete = () => {
    return (
      <div className="step_by_step_complete">
        <Icon size="medium">
          <i className="fa-regular fa-check" />
        </Icon>
      </div>
    );
  };

  const StateActive = () => {
    return (
      <div className="step_by_step_active">
        <div className="step_by_step_active_circle"></div>
      </div>
    );
  };

  return (
    <div className="step_by_step">
      {steps.map((step, index) => {
        const isActive = step.current ? 'active' : '';
        const isComplete = step.complete ? 'complete' : '';
        return (
          <div
            className="step_by_step_container"
            key={index}
            onClick={() => stepClick?.(index)}
          >
            <div
              className={`step_by_step_item ${isActive} ${isComplete}`}
              data-testid={`steps-${index + 1}`}
            >
              <div
                className={`step_by_step_item_status ${isActive} ${isComplete}`}
              >
                {step.complete ? <StateComplete /> : <StateActive />}
              </div>
              {index !== steps.length - 1 && (
                <div
                  className={`step_by_step_item_line ${isActive} ${isComplete}`}
                ></div>
              )}
            </div>
            <div className="step_by_step_container_description">
              <Typography tag="p" weight="p5" className="text-neutral-60">
                {step.title}
              </Typography>
              <Typography
                tag="p"
                weight="p3"
                className={`${isActive ? 'text-neutral-500' : 'text-neutral-100'}`}
              >
                {step.description}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};
