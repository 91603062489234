import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { NewDeal } from '@interfaces/BillingDeal.interfaces';
import api from '@services/api';

export const newDeal = async (payload: NewDeal, contractId: string) => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${endpoint.contracts}/${contractId}/${endpoint.deals}`,
    payload
  );
  const { data } = response;

  return data || [];
};
