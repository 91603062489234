import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextualErrorType } from '@components';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { AppClient } from '../../interfaces/AppClient.interfaces';
import { revokeAppClient } from '../revokeAppClient';
import { appClientKeys } from './appClientKeys';

export const useRevokeAppClientMutation = () => {
  const [customError, setCustomError] = useState<ContextualErrorType>(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Omit<AppClient, 'clientSecret'>,
    ApiError,
    string,
    unknown
  >({
    mutationFn: (appClientId) => revokeAppClient(appClientId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appClientKeys.lists(),
      });
    },
    onError: (error) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
