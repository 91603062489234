import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { ListResponse } from '@interfaces/Common.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import {
  AppClient,
  FindAppClientsFilters,
} from '../../interfaces/AppClient.interfaces';
import { findAppClientList } from '../findAppClientList';
import { appClientKeys } from './appClientKeys';

export const useInfiniteFindAppClientList = (params: FindAppClientsFilters) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<
    ListResponse<Omit<AppClient, 'clientSecret'>>,
    ApiError,
    InfiniteData<ListResponse<Omit<AppClient, 'clientSecret'>>>,
    unknown[],
    string
  >({
    queryKey: appClientKeys.list({ ...params, organizationId }),
    queryFn: ({ pageParam }) =>
      findAppClientList({ ...params, next: pageParam }),
    getNextPageParam: (lastPage) => lastPage?.next || null,
    initialPageParam: '',
    enabled: !!organizationId && !!params.startDate,
  });
};
