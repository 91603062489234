export const featureFlagKeys = {
  all: () => ['feature-flag'],
  details: () => [featureFlagKeys.all(), 'detail'],
  detail: ({
    organizationId,
    userId,
    featureFlagSlug,
  }: {
    organizationId: string | null;
    userId: string | null;
    featureFlagSlug: string;
  }) => [featureFlagKeys.details(), organizationId, userId, featureFlagSlug],
};
