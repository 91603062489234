import { SidebarSection } from '@components';
import {
  DealProductChargeMethod,
  DealProductType,
} from '@enums/BillingDeal.enum';
import { DealDescriptionItem } from '@interfaces/BillingDeal.interfaces';
import {
  Button,
  ErrorMessage,
  Input,
  Select,
  TableDemo,
} from '@portao3-web/ui';
import { moneyMask } from '@utils/formatMasks';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNewDealColumns } from '../../components/useNewDealColumns';

interface ConfigDealForm {
  description: string;
  amount: string;
  closeDate: string;
}

export const DealConfig = () => {
  const { t } = useTranslation();
  const columns = useNewDealColumns({
    deleteLineColumns: (item) => deleteLineColumns(item),
  });

  const {
    setValue,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const configDealWatch = watch('configDeal');

  const form = useForm<ConfigDealForm>({
    mode: 'onChange',
    defaultValues: {
      description: '',
      amount: '',
      closeDate: getValues('recurringPix') ? DealProductType.RECURRENT : '',
    },
  });

  const deleteLineColumns = (item: DealDescriptionItem) => {
    const configDeal = getValues('configDeal');
    const filteredConfigDeal = configDeal.filter(
      (deal: DealDescriptionItem) => deal.identifier !== item.identifier
    );
    setValue('configDeal', filteredConfigDeal, {
      shouldValidate: true,
    });
  };

  const handleSubmit = (data: ConfigDealForm) => {
    const currentConfigDeal = getValues('configDeal') || [];
    setValue(
      'configDeal',
      [
        ...currentConfigDeal,
        {
          id: `${data.description}-${Date.now()}`,
          identifier: data.description,
          type:
            data.closeDate === DealProductType.RECURRENT
              ? DealProductType.RECURRENT
              : DealProductType.ONE_TIME,
          price: Number(data.amount.replace(/\D/g, '')),
          description: data.description,
          chargeMethod: DealProductChargeMethod.LATER,
        },
      ],
      {
        shouldValidate: true,
      }
    );
    form.reset();
  };

  return (
    <SidebarSection
      titleSection="Configuração"
      subTitleSection="Preencha os campos abaixo para prosseguir."
    >
      <div className="flex flex-col">
        <Input
          label="Descrição"
          name="description"
          placeholder="Ex: Mensalidade"
          register={form.register}
          error={!!errors['description']}
          required
        >
          {errors['description']?.message && (
            <ErrorMessage
              message={t(errors['description'].message.toString(), '')}
            />
          )}
        </Input>

        <div className="flex flex-col">
          <div className="flex gap-4">
            <Input
              type="tel"
              placeholder="Inserir valor"
              label="Valor"
              id="amount"
              name="amount"
              data-testid="amount"
              register={form.register}
              error={!!errors['amount']}
              onChange={(event) => {
                const value = moneyMask(event.target.value);
                form.setValue('amount', value, { shouldDirty: true });
              }}
              validationSchema={{
                validate: (value: string) => {
                  if (/^0*$/.test(value.replace(/\D/g, ''))) {
                    return 'O valor é obrigatório';
                  }
                },
              }}
              maxLength={17}
            >
              {errors['amount']?.message && (
                <ErrorMessage message={errors['amount'].message.toString()} />
              )}
            </Input>

            <Select
              label="Tipo da cobrança"
              name="closeDate"
              placeholder="Ex: Recorrente"
              form={form}
              validationSchema={{
                validate: (value: string) => {
                  if (!value) {
                    return 'O tipo da cobrança é obrigatório';
                  }
                  return true;
                },
              }}
              options={[
                {
                  label: 'Cobrança única',
                  value: DealProductType.ONE_TIME,
                },
                {
                  label: 'Recorrente',
                  value: DealProductType.RECURRENT,
                },
              ]}
              disabled={getValues('recurringPix')}
              dataTestId="select-client-state"
            />
          </div>
          <div className="ml-auto mb-4 flex flex-row gap-3">
            <Button
              variant="secondary"
              size="small"
              className="max-content"
              onClick={() => handleSubmit(form.getValues())}
              disabled={!form.formState.isValid}
              data-testid="add-config-deal"
            >
              {t('general.add')}
            </Button>
          </div>
        </div>
      </div>

      <Controller
        name="configDeal"
        control={control}
        render={({ field }) => (
          <>
            <TableDemo
              {...field}
              headerColor
              pinLastColumn
              columns={columns}
              data={configDealWatch || []}
            />
          </>
        )}
      />
    </SidebarSection>
  );
};
