import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import {
  ChangeStatusModal,
  EmptyStateBlock,
  Pagination,
  SearchQuery,
} from '@components';
import { emptyCustomers } from '@constants/emptyState';
import { useQueryParams } from '@hooks';
import { customerEvents } from '@lib/customerIo';
import { Button, TableDemo, Toastr } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useInfiniteCustomersListQuery } from '@services/customers/hooks';
import { useDeleteCustomerMutation } from '@services/customers/hooks/useDeleteCustomerMutation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CostomersColumnsProps,
  useCustomersColumns,
} from './columnsTable/columnsTable';
import { ExportComponent } from './export/ExportComponent';

export const getDeleteCustomerText = (name: string) => {
  return {
    actionButtonLabel: 'Sim, excluir',
    confirmation: 'Deseja mesmo continuar?',
    disclaimer: `O cliente ${name} e todos os dados associados serão definitivamente excluidos da plataforma.`,
    modalTitle: 'Excluir cliente',
    warn: {
      title: 'Esta é uma ação permanente!',
      description:
        'Caso você prossiga com a exclusão deste cliente, não será possível revertê-la.',
    },
  };
};

export const CustomersManagement = () => {
  const { openDrawer } = useDrawer();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const history = useNavigate();
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();

  const handleDeleteCustomer: CostomersColumnsProps = (
    customerId: string,
    name: string,
    document: string
  ) => {
    setShowDeleteCustomerModal({
      open: true,
      customerId: customerId,
      name: name,
      document,
    });
  };

  const initialModalState = {
    open: false,
    customerId: '',
    name: '',
    document: '',
  };

  const customerColumns = useCustomersColumns(handleDeleteCustomer);

  const [showDeleteCustomerModal, setShowDeleteCustomerModal] =
    useState(initialModalState);

  const {
    data: customerList,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteCustomersListQuery(queryParams.get('name'));

  const {
    mutate: deleteCustomer,
    isPending: isDeletingCustomer,
    error: deleteCustomerError,
  } = useDeleteCustomerMutation();

  const customerListService = useMemo(() => {
    const customerListItems = customerList?.pages?.[page]?.items || [];

    return customerListItems?.map((customer) => ({
      ...customer,
      onRowClick: () => {
        history(`/customers/${customer.id}`);
      },
    }));
  }, [customerList, page, history]);

  return (
    <>
      <div className="flex gap-4 mb-4 justify-end items-center">
        <div className="flex-1">
          <SearchQuery
            query="name"
            placeholder={'Busque por cliente ou empresa'}
          />
        </div>

        <ExportComponent />

        <Button
          type="button"
          size="large"
          variant="primary"
          key="edit-realm-user"
          onClick={() => openDrawer('create-payer-client')}
          data-testid="add-customer-button"
        >
          {t('customers.add-customer')}
          <i className="fa-regular fa-plus fa-1x" />
        </Button>
      </div>

      <div className="flex flex-col gap-3">
        <TableDemo
          columns={customerColumns}
          data={customerListService}
          isLoading={isLoading || isFetchingNextPage}
          emptyState={
            <EmptyStateBlock
              details={emptyCustomers}
              button
              buttonLabel="Adicionar cliente"
              buttonAction={() => openDrawer('create-payer-client')}
            />
          }
          pinLastColumn
        />

        <Pagination
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          page={page}
          setPage={setPage}
          pageCount={customerList?.pages.length}
          className="ml-auto"
        />
      </div>
      {showDeleteCustomerModal.open && (
        <ChangeStatusModal
          open={showDeleteCustomerModal.open}
          closeModal={() => setShowDeleteCustomerModal(initialModalState)}
          changeStatus={() =>
            deleteCustomer(showDeleteCustomerModal.customerId, {
              onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['customers'] });
                setShowDeleteCustomerModal(initialModalState);

                customerEvents.customerDeleted({
                  document: showDeleteCustomerModal.document,
                });

                Toastr.success('Cliente excluído com sucesso');
              },
            })
          }
          modalText={getDeleteCustomerText(showDeleteCustomerModal.name)}
          errorService={!!deleteCustomerError}
          isLoading={isDeletingCustomer}
        />
      )}
    </>
  );
};
