import { Skeleton } from '@portao3-web/ui';

import './components.scss';

interface ValueWrapperProps {
  label: string;
  value: string | number | undefined;
  isLoading?: boolean;
  className?: string;
}

export const ValueWrapper = ({
  label,
  value,
  isLoading,
  className,
}: ValueWrapperProps) => {
  return (
    <Skeleton testId="wrapper-loading" isLoading={!!isLoading} height={40}>
      <div className={`flex flex-col ${className || ''}`}>
        <p className="text-p3 text-neutral-80">{label}</p>

        <p className="text-p2 text-neutral-400 break-words">{value || '-'}</p>
      </div>
    </Skeleton>
  );
};
