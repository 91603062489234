import { SidebarSection } from '@components';
import { DealProductType } from '@enums/BillingDeal.enum';
import { DealDescriptionItem } from '@interfaces/BillingDeal.interfaces';
import { Customer } from '@interfaces/Customer.interfaces';
import { useBillingDealColumns } from '@modules/drawer/drawerBillingDeal/useBillingDealColumns';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { TableDemo, Tag } from '@portao3-web/ui';
import { moneyMask } from '@utils/formatMasks';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface DealDetailsProps {
  customer: Customer;
}

export const DealDetails = ({ customer }: DealDetailsProps) => {
  const columns = useBillingDealColumns();
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  const filterSinglePayment = getValues('configDeal').filter(
    (item: DealDescriptionItem) => item.type === DealProductType.ONE_TIME
  );

  const filterRecurringPayment = getValues('configDeal').filter(
    (item: DealDescriptionItem) => item.type === DealProductType.RECURRENT
  );

  const calculateFirstInvoicePayment = useMemo(() => {
    const firstInvoice = filterSinglePayment.reduce(
      (acc: number, curr: DealDescriptionItem) => {
        return acc + curr.price;
      },
      0
    );

    const firstRecurring = filterRecurringPayment[0]?.price || 0;

    return firstInvoice + firstRecurring;
  }, [filterSinglePayment, filterRecurringPayment]);

  const calculateOtherInvoicesPayment = useMemo(() => {
    const otherInvoices = filterRecurringPayment.reduce(
      (acc: number, curr: DealDescriptionItem) => {
        return acc + curr.price;
      },
      0
    );
    return otherInvoices;
  }, [filterRecurringPayment]);

  return (
    <SidebarSection titleSection={t('invoices.info-billing-deal')}>
      <div className="flex flex-col gap-6">
        <ValueWrapper label={t('general.client')} value={customer.name} />
        <ValueWrapper
          label={t('general.description')}
          value={getValues('description')}
        />
        <div className="grid grid-cols-2 gap-6">
          <ValueWrapper
            label={t('invoices.start-deal')}
            value={dayjs(getValues('startAt')).format('DD/MM/YYYY')}
          />

          {getValues('endAt') ? (
            <ValueWrapper
              label={t('invoices.end-deal')}
              value={dayjs(getValues('endAt')).format('DD/MM/YYYY')}
            />
          ) : (
            <div />
          )}

          <ValueWrapper
            label={t('general.closing-date')}
            value={`${getValues('closingAt')} de cada mês`}
          />

          <ValueWrapper
            label={t('general.date-due-date')}
            value={`${getValues('dueAt')} de cada mês`}
          />
        </div>

        <div className="flex flex-col gap-1">
          <p className="text-p3 text-neutral-80">
            {t('general.payment-methods')}
          </p>

          <div className="flex gap-1">
            {(getValues('pix') || getValues('secondaryPix')) && (
              <Tag
                text={t(`invoices.paymentMethod.PIX`)}
                className="text-primary-600"
                showCloseButton={false}
              />
            )}

            {(getValues('bankSlip') || getValues('secondaryBankSlip')) && (
              <Tag
                text={t(`invoices.paymentMethod.BANK_SLIP`)}
                className="text-primary-600"
                showCloseButton={false}
              />
            )}

            {getValues('recurringPix') && (
              <Tag
                text={t(`invoices.paymentMethod.PIX_INITIATION`)}
                className="text-primary-600"
                showCloseButton={false}
              />
            )}
          </div>
        </div>

        <TableDemo
          headerColor
          pinLastColumn
          columns={columns}
          data={getValues('configDeal')}
        />

        <div className="flex w-full gap-3">
          <div className="flex w-full flex-col gap-3">
            <h2 className="text-p1 text-neutral-500">
              {t('invoices.summary-deal')}
            </h2>

            <div className="grid w-full grid-cols-2 gap-3">
              <div className="bg-neutral-20 flex w-full flex-col gap-3 rounded-sm px-6 py-4">
                <p className="text-p2 text-neutral-500">
                  {t('invoices.first-invoice')}
                </p>

                <span className="border-neutral-40 w-full border-b border-solid" />

                <div className="flex justify-between">
                  <p className="text-p1 font-semibold text-neutral-500">
                    {t('general.total')}
                  </p>

                  <p className="text-p1 font-semibold text-neutral-500">
                    {moneyMask(calculateFirstInvoicePayment)}
                  </p>
                </div>
              </div>

              <div className="bg-neutral-20 flex w-full flex-col gap-3 rounded-sm px-6 py-4">
                <p className="text-p2 text-neutral-500">
                  {t('general.other-invoices')}
                </p>

                <span className="border-neutral-40 w-full border-b border-solid" />

                <div className="flex justify-between">
                  <p className="text-p1 font-semibold text-neutral-500">
                    {t('general.total')}
                  </p>

                  <p className="text-p1 font-semibold text-neutral-500">
                    {moneyMask(calculateOtherInvoicesPayment)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarSection>
  );
};
