import { AppClientHeader } from './components/AppClientHeader';
import { AppClientTable } from './components/AppClientTable';

export const AppClient = () => {
  return (
    <div className="flex flex-col gap-8">
      <AppClientHeader />

      <AppClientTable />
    </div>
  );
};
