import { SidebarSection } from '@components';
import { ErrorMessage, Input, Select } from '@portao3-web/ui';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { DealDescriptionStep } from '../../DrawerNewDeal.interface';

export const DealDescription = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<DealDescriptionStep>();

  const form = useFormContext();

  const createArrayFromDays = (days: number) => {
    return Array.from({ length: days }, (_, i) => ({
      label: `Dia ${i + 1}`,
      value: `${i + 1}`,
    }));
  };

  return (
    <SidebarSection
      titleSection="Descrição e Datas do Acordo"
      subTitleSection="Preencha os campos abaixo para prosseguir."
    >
      <div>
        <Input
          label="Descrição"
          register={register}
          name="description"
          placeholder="Ex: Contrato original"
          error={!!errors['description']}
          validationSchema={{
            required: t('error.required-description'),
          }}
        >
          {errors['description']?.message && (
            <ErrorMessage message={errors['description'].message.toString()} />
          )}
        </Input>
        <div className="flex flex-row gap-4">
          <Input
            type="date"
            name="startAt"
            register={register}
            error={!!errors['startAt']}
            label={t('invoices.start-deal')}
            form={form}
            modifiedCalendar
          >
            {errors['startAt']?.message && (
              <ErrorMessage message={errors['startAt'].message.toString()} />
            )}
          </Input>

          <Input
            type="date"
            name="endAt"
            register={register}
            error={!!errors['endAt']}
            label={`${t('invoices.end-deal')} (Opcional)`}
            form={form}
            modifiedCalendar
          >
            {errors['endAt']?.message && (
              <ErrorMessage message={errors['endAt'].message.toString()} />
            )}
          </Input>
        </div>

        <div className="flex flex-row gap-4">
          <Select
            label={t('general.closing-date')}
            name="closingAt"
            form={form}
            validationSchema={{
              required: 'error.required-close-date',
            }}
            placeholder="Ex: Dia 20 de cada mês"
            options={createArrayFromDays(28)}
            dataTestId="closingAt"
          />

          <Select
            label={t('general.date-due-date')}
            name="dueAt"
            form={form}
            validationSchema={{
              required: 'error.required-due-date',
            }}
            placeholder="Ex: Dia 20 de cada mês"
            options={createArrayFromDays(28)}
            dataTestId="dueAt"
          />
        </div>
      </div>
    </SidebarSection>
  );
};
