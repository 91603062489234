import { ContextualErrorType } from '@components';
import { CreateNewDeal, NewDeal } from '@interfaces/BillingDeal.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { Toastr } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { billingDealKeys } from '@services/billingDeal/hooks/billingDealKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { newDeal } from '../services/newDealService';

interface NewDealMutationParams {
  contractId: string;
  payload: CreateNewDeal;
}

export const useNewDealMutation = () => {
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();
  const queryClient = useQueryClient();
  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<
    NewDeal,
    ApiError,
    NewDealMutationParams,
    unknown
  >({
    mutationFn: ({ contractId, payload }: NewDealMutationParams) =>
      newDeal(payload, contractId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: billingDealKeys.lists(),
      });

      closeDrawer();
      Toastr.success(t('billing.deal-created'));
    },
    onError: (error) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
