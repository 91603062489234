export interface ContractPartyAccountAddress {
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  postalCode: string;
}

export enum ContractPartyType {
  VENDOR = 'VENDOR',
  BUYER = 'BUYER',
  INTERMEDIARY = 'INTERMEDIARY',
  WITNESSES = 'WITNESSES',
}

export enum ContractStatus {
  OPEN = 'OPEN',
  TERMINATED = 'TERMINATED',
}

export interface ContractPartyAccountIdentifier {
  realm: string;
  organization: string;
}

export interface ContractPartyAccount {
  type: ContractPartyType;
  name: string;
  document: string;
  email: string;
  phone: string;
  address: ContractPartyAccountAddress;
  account?: {
    identifier?: ContractPartyAccountIdentifier;
  };
}

export interface ContractProps {
  id: string;
  description?: string;
  realm: string;
  organization: string;
  startAt: Date;
  endAt?: Date;
  status: ContractStatus;
  parties: ContractPartyAccount[];
  createdBy: string;
  updatedBy: string;
}
