import { Alert, ContextualError } from '@components';
import { customerEvents } from '@lib/customerIo';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { Button, Modal, Toastr } from '@portao3-web/ui';
import dayjs from 'dayjs';
import { AppClient } from '../interfaces/AppClient.interfaces';
import { useRevokeAppClientMutation } from '../services/hooks/useRevokeAppClientMutation';

export const RevokeAppClientModal = ({
  appClient,
  onClose,
}: {
  appClient: Omit<AppClient, 'clientSecret'>;
  onClose: () => void;
}) => {
  const { mutate, isPending, customError } = useRevokeAppClientMutation();

  const onRevokeAppClient = () => {
    mutate(appClient.id, {
      onSuccess: () => {
        customerEvents.TokenRevoked({ clientId: appClient.id });

        Toastr.success('Token revogado com sucesso');

        onClose();
      },
    });
  };

  return (
    <Modal open title={'Revogar Token'} closeModal={onClose}>
      <div className="max-w-[540px]">
        <div className="flex flex-col gap-4 px-6 pt-4">
          <p className="text-p1 text-neutral-80">
            Deseja mesmo revogar esse token?
          </p>

          {customError && !isPending && <ContextualError error={customError} />}

          <div className="grid grid-cols-2 gap-4">
            <ValueWrapper label="Client ID" value={appClient.clientId} />

            <ValueWrapper
              label="Criado em"
              value={dayjs(appClient.createdAt).format('DD/MM/YYYY')}
            />
          </div>

          <Alert status="warning">
            <p className="text-p1 text-warning-700">
              Atenção, essa será uma ação permanente!
            </p>
          </Alert>
        </div>

        <footer className="flex items-end justify-end px-6 py-3 gap-4 border border-solid border-t border-neutral-40">
          <Button
            size="large"
            variant="secondary"
            onClick={onClose}
            data-testid="cancelButton"
            disabled={isPending}
          >
            Não
          </Button>

          <Button
            size="large"
            onClick={onRevokeAppClient}
            data-testid="confirmButton"
            disabled={isPending}
            isLoading={isPending}
          >
            Sim, revogar
          </Button>
        </footer>
      </div>
    </Modal>
  );
};
