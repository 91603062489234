import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { environment } from '@environments/environment';
import { ListResponse } from '@interfaces/Common.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';
import {
  AppClient,
  FindAppClientsFilters,
} from '../interfaces/AppClient.interfaces';

export const findAppClientList = async (
  params?: FindAppClientsFilters
): Promise<ListResponse<Omit<AppClient, 'clientSecret'>>> => {
  const paramsCopy = { ...params };
  paramsCopy.limit = paramsCopy.limit || limitPerPage;
  removeEmptyObjectAttributes(paramsCopy);

  const response = await api.get(
    `${environment.identityBaseUrl}${realm()}/${organization()}/${
      endpoint.appClient
    }`,
    { params: paramsCopy }
  );

  const { data } = response;

  return data;
};
