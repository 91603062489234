import { Alert, SidebarSection } from '@components';
import { Toggle } from '@portao3-web/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { ToggleState, ToggleType } from '../../DrawerNewDeal.interface';

export const DealPaymentType = () => {
  const { control, watch, getValues, setValue } = useFormContext();

  const handleToggle =
    (type: ToggleType, isSecondary?: boolean) => (isSelected: boolean) => {
      if (type === ToggleState.RECURRING_PIX) {
        setValue('recurringPix', isSelected);
        if (isSelected) {
          setValue('pix', false, {
            shouldValidate: true,
          });
          setValue('bankSlip', false, {
            shouldValidate: true,
          });
        } else {
          setValue('secondaryPix', false, {
            shouldValidate: true,
          });
          setValue('secondaryBankSlip', false, {
            shouldValidate: true,
          });
        }
      } else {
        const field = isSecondary
          ? `secondary${type.charAt(0).toUpperCase() + type.slice(1)}`
          : type;

        setValue(field, isSelected, {
          shouldValidate: true,
        });

        if (!isSecondary && isSelected && watch('recurringPix')) {
          setValue('recurringPix', false, {
            shouldValidate: true,
          });
          setValue('secondaryPix', false, {
            shouldValidate: true,
          });
          setValue('secondaryBankSlip', false, {
            shouldValidate: true,
          });
        }
      }
    };

  return (
    <>
      <SidebarSection
        titleSection="Pagamento"
        subTitleSection="Preencha os campos abaixo para prosseguir."
      >
        <div className="flex flex-col gap-4 mt-3">
          <Controller
            name="recurringPix"
            control={control}
            render={({ field }) => (
              <Toggle
                {...field}
                isSelected={field.value}
                label="Pix Recorrente"
                description="Possibilita os usuários realizarem pagamentos recorrentes via Pix com poucos cliques."
                data-testid="toggle-recurringPix"
                onChange={(isSelected) => {
                  handleToggle(ToggleState.RECURRING_PIX)(isSelected);
                  field.onChange(isSelected);
                }}
                isDisabled={getValues('endDeal') === ''}
              />
            )}
          />

          {getValues('endDeal') === '' && (
            <Alert status="warning">
              <div className="flex flex-col gap-2">
                <p className="text-p2 text-warning-700">Pix Recorrente</p>
                <p className="text-p2 text-neutral-80">
                  O acordo deve ter uma data de fim. Caso queira habilitar este
                  método, volte no passo anterior.
                </p>
              </div>
            </Alert>
          )}

          <Controller
            name="pix"
            control={control}
            render={({ field }) => (
              <Toggle
                {...field}
                isSelected={field.value}
                label="Pix"
                description="Possibilita os usuários realizarem pagamentos por pix."
                data-testid="toggle-pix"
                onChange={(isSelected) => {
                  handleToggle(ToggleState.PIX)(isSelected);
                  field.onChange(isSelected);
                }}
              />
            )}
          />
          <Controller
            name="bankSlip"
            control={control}
            render={({ field }) => (
              <Toggle
                {...field}
                isSelected={field.value}
                label="Boleto"
                description="Possibilita os usuários realizarem pagamentos por boleto."
                data-testid="toggle-bankSlip"
                onChange={(isSelected) => {
                  handleToggle(ToggleState.BANK_SLIP)(isSelected);
                  field.onChange(isSelected);
                }}
              />
            )}
          />
        </div>
      </SidebarSection>

      {watch('recurringPix') && (
        <SidebarSection
          titleSection="Defina os métodos de pagamentos secundários."
          subTitleSection="Selecione uma ou mais opções."
        >
          <div className="flex flex-col gap-4 mt-3">
            <Controller
              name="secondaryPix"
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  isSelected={field.value}
                  label="Pix"
                  description="Possibilita os usuários realizarem pagamentos por pix."
                  data-testid="toggle-secondary-pix"
                  onChange={(isSelected) => {
                    handleToggle(ToggleState.PIX, true)(isSelected);
                    field.onChange(isSelected);
                  }}
                />
              )}
            />
            <Controller
              name="secondaryBankSlip"
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  isSelected={field.value}
                  label="Boleto"
                  description="Possibilita os usuários realizarem pagamentos por boleto."
                  data-testid="toggle-secondary-bankSlip"
                  onChange={(isSelected) => {
                    handleToggle(ToggleState.BANK_SLIP, true)(isSelected);
                    field.onChange(isSelected);
                  }}
                />
              )}
            />
          </div>
        </SidebarSection>
      )}
    </>
  );
};
