import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextualErrorType } from '@components';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import {
  AppClient,
  CreateAppClientInput,
} from '../../interfaces/AppClient.interfaces';
import { createAppClient } from '../createAppClient';
import { appClientKeys } from './appClientKeys';

export const useCreateAppClientMutation = () => {
  const [customError, setCustomError] = useState<ContextualErrorType>(null);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const mutation = useMutation<
    AppClient,
    ApiErrorPin,
    CreateAppClientInput & { pin?: string },
    unknown
  >({
    mutationFn: (body) => createAppClient(body, body?.pin),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appClientKeys.lists(),
      });
    },
    onError: (error) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
