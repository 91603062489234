import { Toastr } from '@portao3-web/ui';

export const copyToClipboard = async (
  text: string | null | undefined,
  successMessage?: string
) => {
  if (!text) {
    Toastr.error('Não foi possível copiar para a área de transferência.');
    return;
  }

  try {
    await navigator.clipboard.writeText(text);
    Toastr.success(successMessage || 'Copiado para área de transferência.');
  } catch {
    Toastr.error(
      'É necessário conceder permissão de acesso à área de transferência.'
    );
  }
};
