import * as yup from 'yup';
import {
  DealConfigStep,
  DealDescriptionStep,
  DealPaymentStep,
} from '../DrawerNewDeal.interface';

export type StepSchemas = typeof stepSchemas;
export type StepKeys = keyof StepSchemas;

export const stepSchemas = {
  0: yup.object<DealDescriptionStep>().shape({
    description: yup.string().required('error.required-description'),
    startAt: yup
      .string()
      .test(
        'test-start-date',
        'Necessário colocar uma data maior que a atual',
        function (value) {
          if (!value) return false; // required
          return new Date(value) >= new Date();
        }
      )
      .required('error.required-start-deal'),
    endAt: yup
      .string()
      .test(
        'test-end-date',
        'A data de término deve ser maior que a data de início',
        function (value) {
          const startDate = this.parent.startAt;
          if (!value) return true; // opcional
          if (startDate && value) {
            return new Date(value) > new Date(startDate);
          }
          return true;
        }
      ),
    closingAt: yup.string().required('error.required-close-date'),
    dueAt: yup.string().required('error.required-due-date'),
  }),
  1: yup
    .object<DealPaymentStep>()
    .shape({
      recurringPix: yup.boolean(),
      pix: yup.boolean(),
      bankSlip: yup.boolean(),
      secondaryPix: yup.boolean().when('recurringPix', {
        is: true,
        then: (schema) => schema.optional(),
      }),
      secondaryBankSlip: yup.boolean().when('recurringPix', {
        is: true,
        then: (schema) => schema.optional(),
      }),
    })
    .test('at-least-one-payment', 'error.default', (values) => {
      const { recurringPix, pix, bankSlip, secondaryPix, secondaryBankSlip } =
        values;

      if (recurringPix) {
        return secondaryPix || secondaryBankSlip;
      }

      return recurringPix || pix || bankSlip;
    }),
  2: yup.object<DealConfigStep>().shape({
    configDeal: yup.array().min(1),
  }),
  3: yup.object().shape({}),
};
