import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { useFilterColumns } from '@hooks/useFilterColumns';
import { Button, Icon, Status, TableDate } from '@portao3-web/ui';
import { AppClient } from '../interfaces/AppClient.interfaces';

export const AppClientColumns = (
  setDeleteAppClientItem: (item: null | Omit<AppClient, 'clientSecret'>) => void
) => {
  const { t } = useTranslation();

  const columns: ColumnDef<Omit<AppClient, 'clientSecret'>>[] = [
    {
      accessorKey: 'description',
      header: t('general.name'),
      cell: ({ row }) => row.original.description,
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'clientId',
      header: 'Client ID',
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
        },
      },
      cell: ({ row }) => row.original.clientId,
    },
    {
      accessorKey: 'createdAt',
      header: 'Data de criação',
      meta: {
        width: 'auto',
      },
      cell: ({ row }) => {
        return <TableDate date={row.original.createdAt} />;
      },
    },
    {
      accessorKey: 'updatedAt',
      header: 'Data de revogação',
      meta: {
        width: 'auto',
      },
      cell: ({ row }) => {
        return <TableDate date={row.original.updatedAt} />;
      },
    },
    {
      accessorKey: 'status',
      header: t('general.status'),
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
        },
      },
      cell: ({ row }) => {
        return (
          <Status status={row.original.status}>
            {t(`enum.AppClientStatus.${row.original.status}`)}
          </Status>
        );
      },
    },
    {
      accessorKey: 'n',
      header: t('general.actions'),
      cell: ({ cell }) => {
        const disabled = cell.row.original.status === 'DEACTIVATED';

        return (
          <div className="flex-1 flex items-center justify-center">
            <Button
              type="button"
              variant="tertiary"
              size="small"
              disabled={disabled}
              onClick={() => setDeleteAppClientItem(cell.row.original)}
            >
              <Icon size="medium" key="view-user-button">
                <i
                  className={`${ICONS.cancel} fa-1x ${disabled ? 'text-neutral-50' : 'text-neutral-100'} `}
                />
              </Icon>
            </Button>
          </div>
        );
      },
      meta: {
        width: 80,
        filter: {
          disabled: true,
        },
      },
    },
  ];

  return useFilterColumns('app-token-list', columns);
};
