import { endpoint, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import {
  AppClient,
  CreateAppClientInput,
} from '../interfaces/AppClient.interfaces';

export const createAppClient = async (
  params: CreateAppClientInput,
  pin?: string
): Promise<AppClient> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${realm()}/${endpoint.appClient}`,
    params,
    { headers: { pin } }
  );

  const { data } = response;

  return data;
};
