import accessKeyImage from '@assets/img/access-key-image.svg';
import { AlertPix } from '@components';
import { ICONS } from '@constants/icons';
import { DrawerCreateAppClient } from '@modules/drawer/drawerCreateAppClient';
import { Button } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useCheckFeatureFlagQuery } from '@services/featureFlag/hooks';
import { handleOpenWpp } from '@utils/openWpp';

export const AppClientHeader = () => {
  const { openDrawer } = useDrawer();

  const { data, isLoading } = useCheckFeatureFlagQuery('app-token');

  const onOpenCreateAppClientDrawer = () => {
    openDrawer(<DrawerCreateAppClient />);
  };

  const onRedirectToDoc = () => {
    window.open('https://developers.portao3.com.br', '_blank');
  };

  return (
    <div className="grid grid-cols-3 h-full grid-row-2 xl:grid-cols-5 gap-6">
      <div className="p-4 border flex col-span-3 gap-4 border-solid border-neutral-40 rounded-lg">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <p className="text-p1 text-neutral-500 font-montserrat-semi-bold">
              Produção
            </p>

            <p className="text-p3 text-neutral-100">
              Para obter acesso à nossa API em produção, clique no botão abaixo
              e crie um token.
            </p>
          </div>

          {!data?.enabled && !isLoading && (
            <AlertPix status="warning">
              <p className="text-p1 text-warning-700">
                Para habilitar o token, você precisa entrar em contato com a
                central de atendimento para liberar essa opção.
              </p>
            </AlertPix>
          )}

          <div className="flex gap-3">
            <Button
              onClick={onOpenCreateAppClientDrawer}
              size="large"
              className="w-max"
              disabled={!data?.enabled}
            >
              <i className="fa-regular fa-key text-p2 mr-2" />
              Gerar token
            </Button>

            {!data?.enabled && !isLoading && (
              <Button
                onClick={() => handleOpenWpp()}
                size="large"
                className="w-max"
                variant="secondary"
              >
                <i className={`${ICONS.wpp} text-p2 mr-2`} />
                Fale com o nosso suporte
              </Button>
            )}
          </div>
        </div>

        <div className="flex items-center">
          <img
            className="h-full max-h-40 min-w-32 !aspect-video"
            src={accessKeyImage}
            alt="Imagem pessoa e chave"
          />
        </div>
      </div>

      <div
        className={`flex flex-col col-span-3 xl:col-span-2 gap-4 p-4 bg-primary-50 rounded-lg ${data?.enabled ? 'h-full ' : 'h-max'}`}
      >
        <div className="flex flex-col gap-1">
          <p className="text-p1 text-neutral-500 font-montserrat-semi-bold">
            Documentação
          </p>

          <p className="text-p3 text-neutral-100">
            Para mais informações, acesse a documentação completa da nossa
            plataforma em:
          </p>
        </div>

        <Button
          variant="secondary"
          onClick={onRedirectToDoc}
          size="large"
          className="w-max"
        >
          developers.portao3.com.br
          <i className="fa-regular fa-arrow-up-right text-p2 ml-2" />
        </Button>
      </div>
    </div>
  );
};
