import { DealDiscount, DealFineAndInterest } from './BillingDeal.interfaces';

export enum PaymentMethod {
  BANK_SLIP = 'BANK_SLIP',
  PIX = 'PIX',
  P2P = 'P2P',
  PIX_INITIATION = 'PIX_INITIATION',
}

export enum InvoiceStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PAID = 'PAID',
  UPCOMING = 'UPCOMING',
}

export enum PaymentUnit {
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
}

export enum PartyAccountBank {
  P3 = 'P3',
}

export enum PartyAccountType {
  WALLET = 'WALLET',
}

export enum InvoiceFinancialImpactType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface InvoiceExternal {
  b3BankSlip?: string;
  b3Pix?: string;
}

export interface PartyAccountAddress {
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface WalletIdentifier {
  realm: string;
  organization: string;
  account: string;
  wallet: string;
}

export interface PartyAccount {
  document: string;
  name: string;
  email?: string;
  bank: PartyAccountBank;
  type: PartyAccountType;
  identifier: WalletIdentifier;
}

export interface DebitPartyAccount {
  document?: string;
  name?: string;
  email?: string;
  bank: PartyAccountBank;
  type: PartyAccountType;
  identifier: WalletIdentifier;
  address?: PartyAccountAddress;
}

export interface CreditParty {
  partyAccount: PartyAccount;
  startAt: Date;
  endAt?: Date;
  unit: PaymentUnit;
  amount: number;
}

export interface DebitParty {
  partyAccount: DebitPartyAccount;
  unit: PaymentUnit;
  amount: number;
  platformWalletName: string;
  platformWalletId: string;
}

export interface InvoiceDealProps {
  id: string;
  realm: string;
  organization: string;
  contract?: string;
  deal?: string;
  dealName?: string;
  description?: string;
  allowedPaymentMethods: PaymentMethod[];
  closingAt: Date;
  dueAt: Date;
  expiresAt: Date;
  debitParties?: DebitParty[];
  fine?: DealFineAndInterest;
  interest?: DealFineAndInterest;
  discount?: DealDiscount;
  amount: number;
  status: InvoiceStatus;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  external: InvoiceExternal;
}

export interface InvoicePaid extends InvoiceDealProps {
  initiationType: PaymentMethod;
}
