import { DealDescriptionItem } from '@interfaces/BillingDeal.interfaces';

export type ToggleType = 'pix' | 'bankSlip' | 'recurringPix';

export enum ToggleState {
  PIX = 'pix',
  BANK_SLIP = 'bankSlip',
  RECURRING_PIX = 'recurringPix',
}

export interface DealDescriptionStep {
  description: string;
  startAt: Date;
  endAt?: Date;
  closingAt: string;
  dueAt: string;
}

export interface DealPaymentStep {
  recurringPix: boolean;
  pix: boolean;
  bankSlip: boolean;
  secondaryPix?: boolean;
  secondaryBankSlip?: boolean;
}

export interface DealConfigStep {
  configDeal: DealDescriptionItem[];
}

export interface DealNewDealForm
  extends DealDescriptionStep,
    DealPaymentStep,
    DealConfigStep {}
