import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { FeatureFlagCheck } from '@interfaces/FeatureFlag.interfaces';
import { useUser } from '@providers';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { checkFeatureFlag } from '../checkFeatureFlag';
import { featureFlagKeys } from './featureFlagKeys';

export const useCheckFeatureFlagQuery = (featureFlagSlug: string) => {
  const organizationId = localStorage.getItem('organization');
  const { id } = useUser();

  return useQuery<FeatureFlagCheck, ApiError, FeatureFlagCheck, unknown[]>({
    queryKey: featureFlagKeys.detail({
      organizationId,
      userId: id,
      featureFlagSlug,
    }),
    queryFn: () => checkFeatureFlag(featureFlagSlug),
    placeholderData: keepPreviousData,
    enabled: !!organizationId && !!id,
  });
};
