import {
  Alert,
  ContextualError,
  InDrawerAuthentication,
  Input,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { initialStateUser } from '@constants/generalInitialStates';
import { ICONS } from '@constants/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthentication } from '@hooks';
import { customerEvents } from '@lib/customerIo';
import {
  AppClient,
  AppClientProducts,
  CreateAppClientInput,
} from '@pages/preferences/components/AppClient/interfaces/AppClient.interfaces';
import { useCreateAppClientMutation } from '@pages/preferences/components/AppClient/services/hooks/useCreateAppClientMutation';
import { Button, Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { copyToClipboard } from '@utils/copyClipboard';
import { validationSchema } from '@utils/validationSchema';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DrawerCreateAppClient = () => {
  const [createdAppClient, setCreatedAppClient] = useState<AppClient | null>(
    null
  );
  const organizationId = localStorage.getItem('organization') as string;

  const userStorage =
    localStorage.getItem('user') || JSON.stringify(initialStateUser);
  const userData = JSON.parse(userStorage);
  const realmId = userData.realm.id;
  const user = userData.id;

  const { closeDrawer } = useDrawer();

  const { mutate, isPending, customError } = useCreateAppClientMutation();

  const { t } = useTranslation();
  const {
    pin,
    controller,
    authSubmit,
    onAuthSuccess,
    onAuthError,
    isAuthentication,
  } = useAuthentication();

  const defaultValues = {
    firstName: '',
  };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(defaultValues)),
  });

  const copyClientId = () => {
    copyToClipboard(
      createdAppClient?.clientId,
      'Client ID copiado com sucesso!'
    );
  };

  const copySecret = () => {
    copyToClipboard(
      createdAppClient?.clientSecret,
      'Client Secret copiado com sucesso!'
    );
  };

  const onSubmit = async (values: Record<string, string>) => {
    const clientId = btoa(values.firstName + new Date().getTime());

    const body: CreateAppClientInput & { pin?: string } = {
      clientId,
      createdBy: user,
      description: values.firstName,
      organizations: [organizationId],
      realm: realmId,
      products: [
        AppClientProducts.BANKING,
        AppClientProducts.BILLING,
        AppClientProducts.IDENTITY,
        AppClientProducts.NOTIFICATION,
        AppClientProducts.PLATFORM,
        AppClientProducts.SCHEDULING,
      ],
      pin,
    };

    mutate(body, {
      onSuccess: (appClient) => {
        onAuthSuccess();
        customerEvents.TokenCreated({ clientId: appClient.id });

        setCreatedAppClient(appClient);
      },
      onError: (error) => {
        onAuthError(error);
      },
    });
  };

  return (
    <Sidebar open title={'Gerar token'} icon={ICONS.key} onClose={closeDrawer}>
      {!createdAppClient && (
        <form
          onSubmit={form.handleSubmit((value) =>
            authSubmit(() => onSubmit(value))
          )}
        >
          {!isAuthentication && (
            <>
              <SidebarSection
                titleSection={'Nome do token'}
                subTitleSection={'Informe o nome deste token'}
              >
                <ContextualError error={customError} />

                <Input
                  name={'firstName'}
                  placeholder={'Inserir nome'}
                  form={form}
                  label={t('general.name')}
                  maxLength={30}
                  showCounter
                />
              </SidebarSection>

              <SidebarFooter>
                <Button
                  type="button"
                  variant="tertiary"
                  size="large"
                  onClick={closeDrawer}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  disabled={form.watch('firstName').length === 0}
                  type="submit"
                  size="large"
                >
                  {t('general.next')}
                </Button>
              </SidebarFooter>
            </>
          )}

          <InDrawerAuthentication isPending={isPending} {...controller} />
        </form>
      )}

      {createdAppClient && (
        <>
          <SidebarSection
            titleSection={'Seu token foi gerado com sucesso!'}
            subTitleSection={'Segue as chaves abaixo:'}
          >
            <Input
              name={'clientId'}
              placeholder={'Client ID'}
              form={form}
              label={'Client ID'}
              value={createdAppClient.clientId}
            />
            <Button
              size="medium"
              className="w-max ml-auto mb-4"
              variant="secondary"
              onClick={copyClientId}
            >
              <i className={`${ICONS['send-backward']} text-p2 mr-2`} />
              Copiar Client ID
            </Button>

            <Input
              name={'clientSecret'}
              placeholder={'Client Secret'}
              form={form}
              label={'Client Secret'}
              value={createdAppClient.clientSecret}
            />

            <Alert status={'warning'}>
              <p className="text-p4 text-neutral-100">
                Após criar o token, o Client Secret não poderá ser visto
                novamente.
              </p>
            </Alert>

            <Button
              size="medium"
              className="w-max ml-auto mb-6"
              variant="secondary"
              onClick={copySecret}
            >
              <i className={`${ICONS['send-backward']} text-p2 mr-2`} />
              Copiar Client Secret
            </Button>

            <Alert status={'info'}>
              <div className="flex flex-col gap-4">
                <p className="text-p4 text-neutral-100">Dicas de segurança</p>

                <p className="text-p4 text-info-500">
                  1 - Nunca compartilhe seu token de API em lugares públicos,
                  como repositórios de código ou fóruns.
                </p>
                <p className="text-p4 text-info-500">
                  2 - Gere novos tokens periodicamente e desative os antigos
                  para minimizar o risco de comprometimento.
                </p>
              </div>
            </Alert>
          </SidebarSection>

          <SidebarFooter>
            <Button type="button" size="large" onClick={closeDrawer}>
              {t('button.finish')}
            </Button>
          </SidebarFooter>
        </>
      )}
    </Sidebar>
  );
};
