import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ClearFiltersQuery,
  DatePickerRangeQuery,
  EmptyStateBlock,
  Pagination,
  SearchQuery,
  SelectQuery,
  TableColumnsFilter,
} from '@components';
import {
  emptyAppClient,
  emptyAppClientWithFilter,
  errorState,
} from '@constants/emptyState';
import { ICONS } from '@constants/icons';
import { useQueryParams } from '@hooks';
import { TableDemo } from '@portao3-web/ui';
import { AppClient, AppClientStatus } from '../interfaces/AppClient.interfaces';
import { useInfiniteFindAppClientList } from '../services/hooks/useInfiniteFindAppClientList';
import { AppClientColumns } from './AppClientColumns';
import { RevokeAppClientModal } from './RevokeAppClientModal';

export const AppClientTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { queryParams } = useQueryParams();
  const [removeAppClientItem, setRevokeAppClientItem] = useState<null | Omit<
    AppClient,
    'clientSecret'
  >>(null);

  const filters = {
    description: queryParams.get('description'),
    status: queryParams.get('status') as AppClientStatus | null,
    order: queryParams.get('order') || 'DESC',
    startDate: queryParams.get('startDate'),
    endDate: queryParams.get('endDate'),
  };

  const {
    data,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isError,
  } = useInfiniteFindAppClientList(filters);

  const { columns, filterColumnsProps } = AppClientColumns(
    setRevokeAppClientItem
  );

  const hasFilters =
    queryParams.get('status') || queryParams.get('description');

  const orderOptions = [
    {
      label: t('general.newest'),
      value: 'DESC',
    },
    {
      label: t('general.oldest'),
      value: 'ASC',
    },
  ];

  const statusOptions = [
    {
      label: t('status.active'),
      value: AppClientStatus.ACTIVE,
    },
    {
      label: 'Revogado',
      value: AppClientStatus.DEACTIVATED,
    },
  ];

  return (
    <div>
      <div className="mb-4 flex flex-wrap gap-3">
        <SearchQuery
          data-testid="search-description-input"
          placeholder={'Nome ou Client ID'}
          query="description"
        />

        <SelectQuery
          onlyOptions
          query="order"
          title={t('general.order')}
          type="single"
          icon={ICONS.order}
          options={orderOptions}
          defaultValues={['DESC']}
          required
        />

        <SelectQuery
          onlyOptions
          query="status"
          title={t('general.status')}
          type="single"
          options={statusOptions}
        />

        <DatePickerRangeQuery size="md" />

        <ClearFiltersQuery queries={['status', 'description']} />

        <TableColumnsFilter {...filterColumnsProps} />
      </div>

      <TableDemo
        columns={columns}
        data={data?.pages?.[page]?.items || []}
        isLoading={isFetching || isFetchingNextPage}
        emptyState={
          <>
            {!isError && !hasFilters && (
              <EmptyStateBlock details={emptyAppClient} />
            )}

            {!isError && hasFilters && (
              <EmptyStateBlock details={emptyAppClientWithFilter} />
            )}

            {isError && <EmptyStateBlock details={errorState} />}
          </>
        }
        pinLastColumn
      />

      <Pagination
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        page={page}
        setPage={setPage}
        pageCount={data?.pages.length}
        className="flex justify-end mt-4"
      />

      {removeAppClientItem && (
        <RevokeAppClientModal
          appClient={removeAppClientItem}
          onClose={() => setRevokeAppClientItem(null)}
        />
      )}
    </div>
  );
};
