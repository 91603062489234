import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ClearFiltersQuery,
  EmptyStateBlock,
  Pagination,
  SearchQuery,
  SelectQuery,
} from '@components';
import {
  emptyCustomersBillingDeals,
  emptyCustomersBillingDealsWithFilter,
  errorCustomersBillingDeals,
} from '@constants/emptyState';
import { useQueryParams } from '@hooks';
import { DrawerBillingDeal } from '@modules/drawer/drawerBillingDeal';
import { Button, Icon, TableDemo } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useInfiniteBillingDealListQuery } from '@services/billingDeal/hooks/useInfiniteBillingDealListQuery';
import { useCustomerBillingDealsColumns } from './useCustomerBillingDealsColumns';

import { DealStatus } from '@enums/BillingDeal.enum';
import { Deal } from '@interfaces/BillingDeal.interfaces';
import { Customer } from '@interfaces/Customer.interfaces';
import { DrawerNewDeal } from '@modules/drawer';

dayjs.extend(utc);

interface CustomerBillingDealsProps {
  customer: Customer;
}

export const CustomersBillingDeals = ({
  customer,
}: CustomerBillingDealsProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { queryParams } = useQueryParams();
  const { customerId } = useParams();
  const { openDrawer } = useDrawer();

  const status = queryParams.getAll('status') as DealStatus[];
  const description = queryParams.get('deal-name') as string;

  const hasFilters = !status || status.length > 0 || description;

  const statusOptions = [
    {
      label: t('general.canceled'),
      value: DealStatus.CANCELED,
    },
    {
      label: t('general.active'),
      value: DealStatus.OPEN,
    },
    {
      label: t('general.terminated'),
      value: DealStatus.TERMINATED,
    },
  ];

  const {
    data: dealData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isError,
    refetch,
  } = useInfiniteBillingDealListQuery({
    status,
    description,
    customerId,
  });

  const dealList = useMemo(() => {
    const handleOpenBillingDeal = (deal: Deal) => {
      openDrawer(<DrawerBillingDeal deal={deal} />);
    };

    return (
      dealData?.pages?.[page]?.items?.map((deal) => ({
        ...deal,
        onRowClick: () => handleOpenBillingDeal(deal),
      })) || []
    );
  }, [dealData, page, openDrawer]);

  const columns = useCustomerBillingDealsColumns();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap gap-3 items-center justify-between w-full">
        <div className="flex flex-wrap gap-3">
          <SearchQuery
            placeholder={t('invoices.deal-name')}
            query="deal-name"
          />

          <SelectQuery
            maxSelectedShow={0}
            query="status"
            title={t('general.status')}
            options={statusOptions}
          />

          <ClearFiltersQuery queries={['status', 'deal-name']} />
        </div>

        <Button
          variant="primary"
          size="large"
          onClick={() =>
            customerId && openDrawer(<DrawerNewDeal customer={customer} />)
          }
        >
          <Icon size="large">
            <i className={`fa-regular fa-plus fa-1x`} />
          </Icon>
          {t('general.new-deal')}
        </Button>
      </div>

      <TableDemo
        headerColor
        columns={columns}
        isLoading={isLoading || isFetchingNextPage}
        data={dealList}
        pinLastColumn
        emptyState={
          <>
            {!hasFilters && !isError && (
              <EmptyStateBlock
                details={emptyCustomersBillingDeals}
                button
                buttonLabel={t('general.new-deal')}
                buttonAction={() =>
                  customerId &&
                  openDrawer(<DrawerNewDeal customer={customer} />)
                }
              />
            )}

            {hasFilters && !isError && (
              <EmptyStateBlock details={emptyCustomersBillingDealsWithFilter} />
            )}

            {isError && (
              <EmptyStateBlock
                details={errorCustomersBillingDeals}
                button
                buttonLabel={t('button.try-again')}
                buttonAction={refetch}
              />
            )}
          </>
        }
      />

      <Pagination
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        page={page}
        setPage={setPage}
        pageCount={dealData?.pages.length}
        className="ml-auto"
      />
    </div>
  );
};
