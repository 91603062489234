import { organization, realm, user } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { FeatureFlagCheck } from '@interfaces/FeatureFlag.interfaces';
import api from '@services/api';

export const checkFeatureFlag = async (
  featureFlagSlug: string
): Promise<FeatureFlagCheck> => {
  const response = await api.get(
    `${environment.identityBaseUrl}${realm()}/${organization()}/${user()}/feature-flags/${featureFlagSlug}/check`
  );

  const { data } = response;

  return data;
};
