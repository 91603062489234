import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { ContractProps } from '@interfaces/Contracts';
import api from '@services/api';
import { Contract } from '../interfaces/Contract.interface';

export const newContract = async (
  payload: Contract
): Promise<ContractProps> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${endpoint.contracts}`,
    payload
  );
  const { data } = response;

  return data || [];
};
