export enum FinancialImpactType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  NONE = 'NONE',
}

export enum InvoiceStatementStatus {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  DONE = 'DONE',
  SCHEDULED = 'SCHEDULED',
}

export enum InvoiceIdentifierType {
  INCREASE = 'INCREASE',
  DISCOUNT = 'DISCOUNT',
}
