import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ErrorMessage,
  Input,
  Select,
  Sidebar,
  Skeleton,
  Spinner,
  Toastr,
} from '@portao3-web/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDrawer } from '@providers';

import {
  ContextualError,
  ContextualErrorType,
  SidebarFooter,
  SidebarSection,
} from '@components';
import {
  useCustomerQuery,
  useUpdateCustomerMutation,
} from '@services/customers/hooks';
import { useCreateCustomerMutation } from '@services/customers/hooks/createCustomerMutation';

import { cities, states } from '@constants/BR_cities_states';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { customerEvents } from '@lib/customerIo';
import { findCep } from '@services/general/findCep';
import { cepMask, cnpjMask, cpfMask, phoneMask } from '@utils/formatMasks';
import { removeSpecialCharacters } from '@utils/removeSpecialCharacters';
import { requiredSchema, validationSchema } from '@utils/validationSchema';

export const createCustomerDefaultValues = {
  docType: 'cpf',
  doc: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  postalCode: '',
  state: '',
  city: '',
  neighborhood: '',
  address: '',
  number: '',
  complement: '',
};

export const DrawerCreateCustomer = () => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const [isCPF, setIsCpf] = useState(true);
  const [error, setError] = useState<ContextualErrorType>(null);

  const { openDrawer, params, closeDrawer } = useDrawer();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const isEditing = !!params?.customerId;

  const customValidation = {
    doc: isCPF ? requiredSchema.cpf : requiredSchema.cnpj,
    firstName: isCPF ? requiredSchema.firstName : requiredSchema.legalName,
    lastName: isCPF ? requiredSchema.lastName : requiredSchema.tradingName,
    phone: requiredSchema.cellphone,
    city: requiredSchema.city,
  };

  const form = useForm<typeof createCustomerDefaultValues>({
    defaultValues: createCustomerDefaultValues,
    resolver: yupResolver(
      validationSchema(
        createCustomerDefaultValues,
        customValidation
      ) as unknown as never
    ),
  });

  const { data: customer, isLoading: isLoadingCustomer } = useCustomerQuery(
    params?.customerId ?? '',
    organizationId
  );

  const { mutate: createCustomerMutate, isPending: isPendingCreateCustomer } =
    useCreateCustomerMutation();

  const { mutate: updateCustomer, isPending: isUpdatingCustomer } =
    useUpdateCustomerMutation();

  const {
    watch,
    formState: { errors },
  } = form;

  const state = watch('state') as keyof typeof cities;

  const formatAddress = (address: string) => {
    return address?.slice(0, 40)?.replaceAll(/[<>:;]+/g, '');
  };

  const citiesOptions = useMemo(() => {
    const citiesList = cities[state] || [];

    return citiesList.map((city) => ({
      label: city,
      value: city,
    }));
  }, [state]);

  const handleCreateClient = (data: typeof createCustomerDefaultValues) => {
    const payloadCPF = {
      name: `${data.firstName} ${data.lastName}`,
      trandingName: '',
    };

    const payloadCNPJ = {
      name: data.firstName,
      trandingName: data.lastName,
    };

    const complementPayload = isCPF ? payloadCPF : payloadCNPJ;

    const payload = {
      document: removeSpecialCharacters(data.doc),
      phoneNumber: removeSpecialCharacters(data.phone),
      email: data.email,
      address: {
        number: data.number.trim(),
        city: data.city || customer?.address.city || '',
        street: formatAddress(data.address),
        neighborhood: data.neighborhood,
        state: data.state,
        postalCode: data.postalCode,
        complement: data.complement,
      },
      ...complementPayload,
    };

    customer
      ? updateCustomer(
          {
            customerId: customer.id,
            payload,
          },
          {
            onSuccess: (response) => {
              queryClient.invalidateQueries({ queryKey: ['customers'] });
              Toastr.success('Cliente atualizado com sucesso');

              customerEvents.customerUpdated({
                document: response.document,
              });

              closeDrawer();
            },
            onError: (error) => {
              setError({
                message: t(
                  `error.CODE_ERROR.${error?.response?.data?.code}`,
                  t('error.default')
                ),
                traceId: error?.response.data?.traceId,
              });
            },
          }
        )
      : createCustomerMutate(payload, {
          onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: ['customers'] });

            Toastr.success('Cliente criado com sucesso');

            customerEvents.customerCreated({
              document: response.document,
            });

            if (params?.flow) {
              sessionStorage.setItem(
                'created-customer',
                JSON.stringify({
                  name: `${response.name} - ${response.email}`,
                  id: response.id,
                })
              );

              return openDrawer('funds-in', {
                flow: params?.flow,
              });
            }

            closeDrawer();
          },
          onError: (error) => {
            setError({
              message: t('error.default'),
              traceId: error?.response?.data?.traceId,
            });
          },
        });
  };

  const handleBack = () => {
    if (params?.flow === 'qr-code') {
      return openDrawer('funds-in', {
        flow: 'qr-code',
      });
    }

    if (params?.flow === 'bank-slip') {
      return openDrawer('funds-in', {
        flow: 'bank-slip',
      });
    }

    closeDrawer();
  };

  useEffect(() => {
    if (customer) {
      const customerType = customer.document.length === 11 ? 'cpf' : 'cnpj';
      setIsCpf(customerType === 'cpf');
      const firstName =
        customerType === 'cpf' ? customer.name.split(' ')[0] : customer.name;
      const lastName =
        customerType === 'cpf'
          ? customer?.name?.replaceAll('  ', ' ').split(' ').slice(1).join(' ')
          : customer.trandingName;

      const city =
        cities[customer.address.state as keyof typeof cities].find(
          (item) => item === customer.address.city
        ) || '';

      form.reset({
        address: customer.address.street,
        city,
        complement: customer.address.complement,
        doc:
          customerType === 'cpf'
            ? cpfMask(customer.document)
            : cnpjMask(customer.document),
        docType: customer.documentType.toLowerCase(),
        firstName,
        lastName,
        neighborhood: customer.address.neighborhood,
        number: customer.address.number,
        phone: phoneMask(
          customer.phoneNumber.length === 13
            ? customer.phoneNumber.slice(2, 13)
            : customer.phoneNumber
        ),
        postalCode: cepMask(customer.address.postalCode),
        state: customer.address.state,
        email: customer.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const searchAddress = async (cep: string) => {
    try {
      const addressCep = await findCep(cep);

      if (addressCep && !addressCep.erro) {
        addressCep.uf && form.setValue('state', addressCep.uf);
        addressCep.localidade && form.setValue('city', addressCep.localidade);
        addressCep.bairro &&
          form.setValue('neighborhood', addressCep.bairro.slice(0, 20));
        addressCep.logradouro &&
          form.setValue('address', addressCep.logradouro.slice(0, 40));
      }
    } catch (error) {
      const errorApi = error as ApiError;
      setError({
        message: t('error.default'),
        traceId: errorApi?.response?.data?.traceId,
      });
    }
  };

  useEffect(() => {
    form.clearErrors(['doc', 'firstName', 'lastName']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCPF]);

  return (
    <Sidebar
      open
      title={
        params?.customerId
          ? t('customers.edit-customer')
          : t('customers.add-customer')
      }
      icon="fa-regular fa-briefcase"
      onClose={closeDrawer}
    >
      <ContextualError error={error} />
      <form onSubmit={form.handleSubmit(handleCreateClient)}>
        <SidebarSection
          numberSection={1}
          titleSection={t('customers.customer-type')}
          subTitleSection={''}
        >
          <Skeleton
            isLoading={isLoadingCustomer}
            height={'62px'}
            className="mb-4"
          >
            <Select
              label={t('general.select-doc')}
              name="docType"
              form={form}
              options={[
                {
                  label: 'CPF',
                  value: 'cpf',
                },
                {
                  label: 'CNPJ',
                  value: 'cnpj',
                },
              ]}
              onclick={(value) => setIsCpf(value === 'cpf')}
              disabled={isEditing}
              dataTestId="select-type-document-client"
            />
          </Skeleton>

          <Skeleton
            isLoading={isLoadingCustomer}
            height={'62px'}
            className="mb-4"
          >
            <Input
              label={isCPF ? 'CPF' : 'CNPJ'}
              name="doc"
              register={form.register}
              onChange={(event) => {
                form.setValue(
                  'doc',
                  isCPF
                    ? cpfMask(event.target.value)
                    : cnpjMask(event.target.value)
                );
              }}
              placeholder={
                isCPF ? 'Ex: 000.000.000-00' : 'Ex: 00.000.000/0000-00'
              }
              maxLength={isCPF ? 14 : 18}
              error={!!errors['doc']}
              disabled={isEditing}
            >
              {errors['doc']?.message && (
                <ErrorMessage
                  message={t(errors['doc'].message.toString(), '')}
                />
              )}
            </Input>
          </Skeleton>
        </SidebarSection>

        <SidebarSection
          numberSection={2}
          titleSection={t('customers.customer-id')}
          subTitleSection={''}
        >
          {isCPF && (
            <div className="flex flex-row gap-4">
              <Skeleton
                isLoading={isLoadingCustomer}
                height={'62px'}
                className="mb-4"
              >
                <Input
                  label={t('general.name')}
                  name="firstName"
                  placeholder="Ex: João"
                  register={form.register}
                  error={!!errors['firstName']}
                  disabled={isEditing}
                >
                  {errors['firstName']?.message && (
                    <ErrorMessage
                      message={t(errors['firstName'].message.toString(), '')}
                    />
                  )}
                </Input>
              </Skeleton>

              <Skeleton
                isLoading={isLoadingCustomer}
                height={'62px'}
                className="mb-4"
              >
                <Input
                  label={t('general.last-name')}
                  name="lastName"
                  placeholder="Ex: Silva"
                  register={form.register}
                  error={!!errors['lastName']}
                  disabled={isEditing}
                >
                  {errors['lastName']?.message && (
                    <ErrorMessage
                      message={t(errors['lastName'].message.toString(), '')}
                    />
                  )}
                </Input>
              </Skeleton>
            </div>
          )}
          {!isCPF && (
            <>
              <Skeleton
                isLoading={isLoadingCustomer}
                height={'62px'}
                className="mb-4"
              >
                <Input
                  label={t('general.legal-name')}
                  placeholder="Ex: Portão 3 LTDA"
                  name="firstName"
                  register={form.register}
                  error={!!errors['firstName']}
                  disabled={isEditing}
                >
                  {errors['firstName']?.message && (
                    <ErrorMessage
                      message={t(errors['firstName'].message.toString(), '')}
                    />
                  )}
                </Input>
              </Skeleton>

              <Skeleton
                isLoading={isLoadingCustomer}
                height={'62px'}
                className="mb-4"
              >
                <Input
                  label={t('general.trading-name')}
                  name="lastName"
                  placeholder="Ex: Portão 3"
                  register={form.register}
                  error={!!errors['lastName']}
                  disabled={isEditing}
                >
                  {errors['lastName']?.message && (
                    <ErrorMessage
                      message={t(errors['lastName'].message.toString(), '')}
                    />
                  )}
                </Input>
              </Skeleton>
            </>
          )}
        </SidebarSection>

        <SidebarSection
          numberSection={3}
          titleSection={t('general.contact')}
          subTitleSection={''}
        >
          <div className="flex flex-row gap-4">
            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Input
                label={t('general.cellphone')}
                name="phone"
                placeholder="Ex: (00) 00000-0000"
                register={form.register}
                onChange={({ target }) => {
                  form.setValue('phone', phoneMask(target.value));
                }}
                error={!!errors['phone']}
                maxLength={15}
              >
                {errors['phone']?.message && (
                  <ErrorMessage
                    message={t(errors['phone'].message.toString(), '')}
                  />
                )}
              </Input>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Input
                label={t('billing.email-billing')}
                name="email"
                register={form.register}
                placeholder="Ex: cobrança@portao3.com.br"
                error={!!errors['email']}
              >
                {errors['email']?.message && (
                  <ErrorMessage
                    message={t(errors['email'].message.toString(), '')}
                  />
                )}
              </Input>
            </Skeleton>
          </div>
        </SidebarSection>

        <SidebarSection
          numberSection={4}
          titleSection={t('general.address')}
          subTitleSection={''}
        >
          <div className="flex flex-row gap-4">
            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Input
                label={t('general.postalCode')}
                name="postalCode"
                placeholder="Ex: 00000-000"
                register={form.register}
                onChange={({ target }) => {
                  form.setValue('postalCode', cepMask(target.value));
                  const cepCleared = target.value.replace('-', '');

                  if (cepCleared.length === 8) {
                    searchAddress(cepCleared);
                  }
                }}
                error={!!errors['postalCode']}
                maxLength={9}
              >
                {errors['postalCode']?.message && (
                  <ErrorMessage
                    message={t(errors['postalCode'].message.toString(), '')}
                  />
                )}
              </Input>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Select
                label="Estado"
                name="state"
                placeholder="EX: Minas Gerais"
                form={form}
                options={states}
                search
                dataTestId="select-client-state"
              />
            </Skeleton>
          </div>

          <div className="flex flex-row gap-4">
            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Select
                label={t('general.city')}
                name="city"
                placeholder={customer?.address?.city || 'EX: Uberlândia'}
                searchPlaceholder={
                  form.getValues('state')
                    ? 'Pesquise aqui'
                    : 'Selecione um Estado'
                }
                form={form}
                options={citiesOptions}
                search
                dataTestId="select-client-city"
              />
            </Skeleton>

            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Input
                label={t('general.neighborhood')}
                name="neighborhood"
                placeholder="Ex: Karaíba"
                register={form.register}
                error={!!errors['neighborhood']}
                maxLength={20}
              >
                {errors['neighborhood']?.message && (
                  <ErrorMessage
                    message={t(errors['neighborhood'].message.toString(), '')}
                  />
                )}
              </Input>
            </Skeleton>
          </div>

          <Skeleton
            isLoading={isLoadingCustomer}
            height={'62px'}
            className="mb-4"
          >
            <Input
              label={t('general.address')}
              name="address"
              placeholder="Ex: Rua Marcolino Ribeiro"
              register={form.register}
              error={!!errors['address']}
              maxLength={40}
              onChange={({ target }) => {
                form.setValue('address', formatAddress(target.value));
              }}
            >
              {errors['address']?.message && (
                <ErrorMessage
                  message={t(errors['address'].message.toString(), '')}
                />
              )}
            </Input>
          </Skeleton>

          <div className="flex flex-row gap-4">
            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Input
                label={t('general.number')}
                name="number"
                register={form.register}
                placeholder="Ex: 1830"
                error={!!errors['number']}
              >
                {errors['number']?.message && (
                  <ErrorMessage
                    message={t(errors['number'].message.toString(), '')}
                  />
                )}
              </Input>
            </Skeleton>

            <Skeleton
              isLoading={isLoadingCustomer}
              height={'62px'}
              className="mb-4"
            >
              <Input
                label={t('general.complement')}
                name="complement"
                placeholder="Ex: Próx a árvore"
                register={form.register}
              />
            </Skeleton>
          </div>
        </SidebarSection>

        <SidebarFooter>
          <Button
            type="button"
            size="large"
            variant="tertiary"
            onClick={handleBack}
            disabled={isPendingCreateCustomer || isUpdatingCustomer}
          >
            {t('button.back')}
          </Button>

          <Button
            size="large"
            type="submit"
            variant="primary"
            disabled={isPendingCreateCustomer || isUpdatingCustomer}
            data-testid="create-customer-button"
          >
            {isPendingCreateCustomer || isUpdatingCustomer ? (
              <Spinner />
            ) : (
              t('button.finish')
            )}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};
