import { endpoint, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import { AppClient } from '../interfaces/AppClient.interfaces';

export const revokeAppClient = async (
  appClientId: string
): Promise<Omit<AppClient, 'clientSecret'>> => {
  const response = await api.put(
    `${environment.identityBaseUrl}${realm()}/${
      endpoint.appClient
    }/${appClientId}/deactivate`
  );

  const { data } = response;

  return data;
};
