export const DrawerSteps = [
  {
    current: true,
    complete: false,
    title: 'Passo 1',
    description: 'Descrição',
  },
  {
    current: false,
    complete: false,
    title: 'Passo 2',
    description: 'Pagamento',
  },
  {
    current: false,
    complete: false,
    title: 'Passo 3',
    description: 'Configuração',
  },
  {
    current: false,
    complete: false,
    title: 'Passo 4',
    description: 'Revisão final',
  },
];

export const StepsField = {
  0: ['description', 'startDeal', 'endAt', 'closingAt', 'dueAt'],
  1: ['recurringPix', 'pix', 'bankSlip', 'secondaryPix', 'secondaryBankSlip'],
  2: ['configDeal'],
  3: [],
};
