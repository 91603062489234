import { InvoiceDealProps, InvoiceStatus } from '@interfaces/Invoices';
import { DrawerGenerateInvoicePayment } from '@modules/drawer';
import {
  Accordion,
  DropDownList,
  Icon,
  Skeleton,
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableRow,
  Tag,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { getStatusStyle } from '@utils/BillingStatus';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { renderTableHeader } from '@utils/renderTableHeader';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialStateEntries } from '../../constants/initialStateInvoice';
import { useGetEntriesInvoice } from '../../hooks/useInvoiceMutation';

interface AccordionItemProps {
  invoice: InvoiceDealProps;
  accordionId: string;
  showDebitWallet: (value: boolean) => void;
  selectInvoice: (invoice: InvoiceDealProps) => void;
}

interface MenuOption {
  label: string;
  action: () => void;
}

export const AccordionItem = ({
  invoice,
  accordionId,
  showDebitWallet,
  selectInvoice,
}: AccordionItemProps) => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
  const [showOptions, setShowOptions] = useState(false);
  const thead = ['&nbsp;', 'Data e hora', 'Descrição', 'Qtd', 'Valor'];

  const statusBilling = {
    PAID: 'Paga',
    DELAYED: 'Em atraso',
    OPEN: 'Aberta',
    PROCESSING: 'Processando',
    FAILED: 'Falha no processamento',
    CLOSED: 'Disponível para pagamento',
    EXPIRED: 'Expirado',
    UPCOMING: 'Em breve',
  };

  const toggleAccordion = (accordionId: string) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [accordionId]: !prevState[accordionId],
    }));
  };

  const generateMenu = (invoice: InvoiceDealProps): MenuOption[] => {
    const menuOptions: MenuOption[] = [];

    const actionsMap: { [key: string]: () => void } = {
      BANK_SLIP: () => {
        openDrawer(
          <DrawerGenerateInvoicePayment
            invoice={invoice}
            type={'bank-slip'}
            buyer
          />
        );
      },
      PIX: () =>
        openDrawer(
          <DrawerGenerateInvoicePayment invoice={invoice} type={'pix'} buyer />
        ),
      P2P: () => {
        selectInvoice(invoice);
        showDebitWallet(true);
      },
    };

    const labelsMap: { [key: string]: string } = {
      BANK_SLIP: 'Pagar com boleto',
      P2P: 'Débito na carteira',
      PIX: `${t('general.generate')} ${t(`general.pix`)}`,
    };

    if (invoice.status !== InvoiceStatus.PAID) {
      invoice.allowedPaymentMethods.sort().forEach((option) => {
        if (actionsMap[option]) {
          menuOptions.push({
            label: labelsMap[option],
            action: actionsMap[option],
          });
        }
      });
    }

    return menuOptions;
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setShowOptions(false);
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const {
    mutate: getEntries,
    data: entries,
    isPending: isLoadingEntries,
  } = useGetEntriesInvoice();

  return (
    <Accordion
      setIsOpen={() => {
        toggleAccordion(accordionId);
        if (!isOpen[accordionId]) {
          getEntries(invoice.id);
        }
      }}
      isOpen={!!isOpen[accordionId]}
    >
      <div className="invoices_accordion_header" data-testid={accordionId}>
        <div className="invoices_accordion_header--info">
          <div className="icon-arrow">
            <Icon size="xlarge">
              <i
                className={`fa-regular ${
                  isOpen[accordionId] ? `fa-chevron-up` : `fa-chevron-down`
                } text-neutral-500`}
              />
            </Icon>
          </div>
          <div className="invoices_accordion_header_document">
            <Icon size="xlarge">
              <i className="fa-regular fa-file-invoice" />
            </Icon>
          </div>
          <div className="invoices_accordion_header_text">
            <Typography tag="p" color="var(--product-neutral-n500)" weight="p2">
              {invoice.description}
            </Typography>
          </div>
        </div>
        <div className="invoices_accordion_header--info">
          <div className="invoices_accordion_header_values">
            <Icon size="xlarge">
              <i className="fa-regular fa-calendar" />
            </Icon>
            <Typography tag="p" weight="p4" color="var(--product-neutral-n80)">
              FECHAMENTO
            </Typography>
            <Typography tag="p" weight="p4" color="var(--product-neutral-n500)">
              {dayjs.utc(invoice.closingAt).format('DD/MM/YYYY')}
            </Typography>
          </div>

          <div className="invoices_accordion_header_values">
            <Icon size="xlarge">
              <i className="fa-regular fa-calendar" />
            </Icon>
            <Typography tag="p" weight="p4" color="var(--product-neutral-n80)">
              VENCIMENTO
            </Typography>
            <Typography tag="p" weight="p4" color="var(--product-neutral-n500)">
              {dayjs.utc(invoice.dueAt).format('DD/MM/YYYY')}
            </Typography>
          </div>

          <Tag
            text={statusBilling[invoice.status]}
            showCloseButton={false}
            icon={
              <Icon size="">
                <i
                  className={`fa-regular ${
                    getStatusStyle(invoice.status).icon
                  }`}
                />
              </Icon>
            }
            textColor={getStatusStyle(invoice.status).color}
            backgroundColor={getStatusStyle(invoice.status).background}
          />

          <Typography tag="p" weight="p4" color="var(--product-neutral-n500)">
            {calcCurrencyValue(invoice.amount, 'BRL')}
          </Typography>

          {invoice.status === InvoiceStatus.CLOSED && (
            <div
              onClick={(e) => {
                document.body.click();
                e.stopPropagation();

                setShowOptions(!showOptions);
              }}
              data-ignore-accordion
              data-testid={`option-${accordionId}`}
            >
              <i className="fa-regular text-neutral-80 fa-ellipsis hover:bg-primary-50 text-p1 rounded-md px-2" />
              {showOptions && <DropDownList list={generateMenu(invoice)} />}
            </div>
          )}
        </div>
      </div>

      <div className="invoices_accordion_content">
        <Table>
          <TableHead>
            <TableRow>
              {thead.map((columnTitle: string, key: number) =>
                renderTableHeader(columnTitle, key)
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isLoadingEntries
              ? initialStateEntries.items
              : entries?.items
            )?.map((entrie, index) => {
              return (
                <TableRow key={index}>
                  <TableCol>&nbsp;</TableCol>
                  <TableCol>
                    <Skeleton isLoading={isLoadingEntries}>
                      <Typography
                        tag="p"
                        weight="p4"
                        color="var(--product-neutral-n500)"
                      >
                        {dayjs(entrie.createdAt).format('DD/MM/YYYY')}
                      </Typography>
                      <Typography
                        tag="p"
                        color="var(--product-neutral-n100)"
                        weight="p3"
                      >
                        {dayjs(entrie.createdAt).format('HH:mm')}
                      </Typography>
                    </Skeleton>
                  </TableCol>
                  <TableCol>
                    <Skeleton isLoading={isLoadingEntries}>
                      <Typography
                        tag="p"
                        weight="p4"
                        color="var(--product-neutral-n500)"
                      >
                        {entrie.identifier}
                      </Typography>
                      <Typography
                        tag="p"
                        color="var(--product-neutral-n100)"
                        weight="p3"
                      >
                        {dayjs(entrie.createdAt).format('HH:mm')}
                      </Typography>
                    </Skeleton>
                  </TableCol>
                  <TableCol>
                    <Skeleton isLoading={isLoadingEntries}>
                      <Typography
                        tag="p"
                        weight="p4"
                        color="var(--product-neutral-n500)"
                      >
                        {entries?.items.length ||
                          initialStateEntries.items.length}
                      </Typography>
                    </Skeleton>
                  </TableCol>
                  <TableCol>
                    <Skeleton isLoading={isLoadingEntries}>
                      <Typography
                        tag="p"
                        weight="p4"
                        color="var(--product-neutral-n500)"
                      >
                        {calcCurrencyValue(entrie.amount, 'BRL')}
                      </Typography>
                    </Skeleton>
                  </TableCol>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Accordion>
  );
};
