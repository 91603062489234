import { FindAppClientsFilters } from '../../interfaces/AppClient.interfaces';

export const appClientKeys = {
  all: () => ['app-token'],
  details: () => [...appClientKeys.all(), 'detail'],
  lists: () => [...appClientKeys.all(), 'list'],
  list: (params: FindAppClientsFilters & { organizationId: string | null }) => [
    ...appClientKeys.lists(),
    params,
  ],
};
