import { Button } from '../../../../button/Button';
import { ShadBadge } from '../../../shadBadge';
import { ShadCheckbox } from '../../../shadCheckbox';
import {
  ShadCommand,
  ShadCommandEmpty,
  ShadCommandGroup,
  ShadCommandInput,
  ShadCommandItem,
  ShadCommandList,
  ShadCommandSeparator,
} from '../../../shadCommand';
import {
  ShadPopover,
  ShadPopoverContent,
  ShadPopoverTrigger,
} from '../../../shadPopover';

interface FilterOption {
  label: string;
  value: string;
}

interface Labels {
  selected: string;
  notFound: string;
  clearFilters: string;
}

interface TableMultiSelectFilterProps {
  title?: string;
  options: FilterOption[];
  selection: string[];
  setSelection: (value: string[]) => void;
  labels: Labels;
  maxSelectedShow?: number;
  type?: 'multi' | 'single';
  testId?: string;
  onlyOptions?: boolean;
  icon?: string;
  required?: boolean;
}

export const TableMultiSelectFilter = ({
  title,
  options,
  selection,
  setSelection,
  labels,
  maxSelectedShow = 2,
  type = 'multi',
  testId,
  onlyOptions,
  required,
  icon,
}: TableMultiSelectFilterProps) => {
  const selectedValues = new Set(selection);

  return (
    <ShadPopover>
      <ShadPopoverTrigger asChild>
        <Button
          variant="secondary"
          size="medium"
          data-testid={testId}
          style={{ paddingRight: 8, paddingLeft: 8 }}
        >
          <i className={` ${icon || 'fa-regular fa-circle-plus'} text-p2`} />
          {title}
          {selectedValues.size > 0 && (
            <>
              <i className="fa-regular fa-pipe text-p1 text-primary-100 mx-1" />
              <ShadBadge
                variant="secondary"
                className={`rounded-sm px-1 ${selectedValues.size === 1 ? 'hidden' : 'lg:hidden'} `}
              >
                {selectedValues.size}
              </ShadBadge>
              <div
                className={`${selectedValues.size === 1 ? '' : 'hidden'} space-x-1 lg:flex`}
              >
                {selectedValues.size > maxSelectedShow ? (
                  <ShadBadge
                    variant="secondary"
                    className="rounded-sm px-1 py-[0.5px]"
                  >
                    {`${selectedValues.size} ${labels.selected}`}
                  </ShadBadge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <ShadBadge
                        variant="secondary"
                        key={option.value}
                        className="rounded-sm px-1 py-[0.5px]"
                      >
                        {option.label}
                      </ShadBadge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </ShadPopoverTrigger>
      <ShadPopoverContent className="w-[200px] p-0" align="start">
        <ShadCommand>
          {!onlyOptions && (
            <ShadCommandInput
              placeholder={title}
              className="placeholder:text-p3 text-p3"
            />
          )}
          <ShadCommandList>
            <ShadCommandEmpty>{labels.notFound}</ShadCommandEmpty>
            <ShadCommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <ShadCommandItem
                    data-testid={`select-option-${option.value}`}
                    key={option.value}
                    onSelect={() => {
                      if (type === 'multi') {
                        if (isSelected) {
                          if (selectedValues.size === 1 && required) return;
                          selectedValues.delete(option.value);
                        } else {
                          selectedValues.add(option.value);
                        }
                        const filterValues = Array.from(selectedValues);
                        setSelection(filterValues.length ? filterValues : []);

                        return;
                      }

                      if (type === 'single' && isSelected && !required) {
                        setSelection([]);
                      } else {
                        setSelection([option.value]);
                      }
                    }}
                  >
                    <ShadCheckbox
                      variant={type === 'single' ? 'radio' : 'checkbox'}
                      checked={isSelected}
                      className="mr-2"
                    />
                    <span className="text-p3">{option.label}</span>
                  </ShadCommandItem>
                );
              })}
            </ShadCommandGroup>
            {selectedValues.size > 0 && !onlyOptions && (
              <>
                <ShadCommandSeparator />
                <ShadCommandGroup>
                  <ShadCommandItem
                    onSelect={() => setSelection([])}
                    className="justify-center text-center"
                  >
                    {labels.clearFilters}
                  </ShadCommandItem>
                </ShadCommandGroup>
              </>
            )}
          </ShadCommandList>
        </ShadCommand>
      </ShadPopoverContent>
    </ShadPopover>
  );
};
