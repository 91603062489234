import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { SidebarFooter, SidebarSection } from '@components';
import { ICONS } from '@constants/icons';
import { Button, Input, Sidebar, Tag } from '@portao3-web/ui';
import { copyToClipboard } from '@utils/copyClipboard';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { ValueWrapper } from '../drawerFundsIn/components/ValueWrapper';

import { InvoiceStatementStatus } from '@enums/Invoices.enum';
import { InvoiceDealProps } from '@interfaces/Invoices';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';

interface DrawerInvoiceStatementRecurringPixProps {
  invoice: InvoiceDealProps;
  statement: InvoiceStatementProps;
  onClose: () => void;
}

export const DrawerInvoiceStatementRecurringPix = ({
  invoice,
  statement,
  onClose,
}: DrawerInvoiceStatementRecurringPixProps) => {
  const { t } = useTranslation();

  const statusStatement = {
    [InvoiceStatementStatus.DONE]: {
      text: t('invoices.InvoiceStatementStatus.PAID'),
      icon: ICONS['circle-check'],
      background: 'var(--product-success-s50)',
      color: 'var(--product-success-s500)',
    },
    [InvoiceStatementStatus.PAID]: {
      text: t('invoices.InvoiceStatementStatus.PAID'),
      icon: ICONS['circle-check'],
      background: 'var(--product-success-s50)',
      color: 'var(--product-success-s500)',
    },
    [InvoiceStatementStatus.CANCELED]: {
      text: t('invoices.InvoiceStatementStatus.CANCELED'),
      icon: ICONS['circle-exclamation'],
      background: 'var(--product-danger-d50)',
      color: 'var(--product-danger-d500)',
    },
    [InvoiceStatementStatus.CREATED]: {
      text: t('invoices.InvoiceStatementStatus.CREATED'),
      icon: ICONS['circle-dashed'],
      background: 'var(--product-warning-w50)',
      color: 'var(--product-warning-w700)',
    },
    [InvoiceStatementStatus.PROCESSING]: {
      text: t('invoices.InvoiceStatementStatus.PROCESSING'),
      icon: ICONS['circle-dashed'],
      background: 'var(--product-info-i50)',
      color: 'var(--product-info-i700)',
    },
    [InvoiceStatementStatus.SCHEDULED]: {
      text: t('invoices.InvoiceStatementStatus.SCHEDULED'),
      icon: ICONS['clock'],
      background: 'var(--product-info-i50)',
      color: 'var(--product-info-i700)',
    },
  };

  const dueAt =
    statement.paymentData?.pixInitiation?.scheduledTo || invoice?.dueAt;

  const handleCopyLink = () => {
    copyToClipboard(statement.paymentData?.pixInitiation?.paymentUrl);
  };

  return (
    <Sidebar
      open={!!statement}
      title={t('invoices.invoice-recurring-payment')}
      icon={ICONS.pix}
      onClose={onClose}
    >
      <SidebarSection titleSection={t('billing.billing-details')}>
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-2 gap-6">
            <ValueWrapper
              label={t('schedule-payment.scheduling-date')}
              value={dayjs?.utc(dueAt)?.format('DD/MM/YYYY')}
            />

            <ValueWrapper
              label={t('general.value')}
              value={calcCurrencyValue(statement?.originalAmount, 'BRL')}
            />

            {invoice?.fine?.amount && (
              <ValueWrapper
                label={t('general.fine')}
                value={invoice.fine.amount}
              />
            )}

            {invoice?.interest?.amount && (
              <ValueWrapper
                label={t('general.interest')}
                value={`${invoice.interest?.amount * 100}%`}
              />
            )}
          </div>
          <div className="grid grid-cols-1 gap-6">
            <ValueWrapper
              label={t('general.description')}
              value={statement?.description}
            />
          </div>

          <div className="grid grid-cols-1 gap-6">
            <Tag
              text={statusStatement[statement.status].text}
              showCloseButton={false}
              icon={
                <i
                  className={`text-p3 ${statusStatement[statement.status].icon}`}
                />
              }
              textColor={statusStatement[statement.status].color}
              backgroundColor={statusStatement[statement.status].background}
            />
          </div>

          <div className="my-4 flex flex-col gap-4">
            <>
              <div className="flex flex-col">
                <Input
                  name="some"
                  register={() => ({})}
                  readOnly
                  data-testid="billing-payment-url"
                  type="text"
                  label={t('general.payment-url')}
                  value={statement.paymentData?.pixInitiation?.paymentUrl}
                />

                <div className="mb-4 ml-auto flex flex-row gap-3">
                  <Button
                    size="small"
                    className="max-content"
                    onClick={handleCopyLink}
                  >
                    {t('billing.copy-link')}
                  </Button>
                </div>
              </div>
            </>
          </div>
        </div>
      </SidebarSection>

      <SidebarFooter>
        <Button size="large" onClick={onClose}>
          {t('button.finish')}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};
