import { ContextualErrorType } from '@components';
import { ContractProps } from '@interfaces/Contracts';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Contract } from '../interfaces/Contract.interface';
import { newContract } from '../services/newContractService';
import { newContractKeys } from './newContractKeys';

export const useCreateNewContractMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<ContractProps, ApiError, Contract, unknown>({
    mutationFn: (payload: Contract) => newContract(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: newContractKeys.lists(),
      });
    },
    onError: (error) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
