import { ContextualError, SidebarFooter } from '@components';
import { ICONS } from '@constants/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContractPartyType } from '@interfaces/Contracts';
import { Customer } from '@interfaces/Customer.interfaces';
import { PaymentMethod, PaymentUnit } from '@interfaces/Invoices';
import { Button, Sidebar, StepByStep } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DrawerSteps, StepsField } from './constants/DrawerSteps';
import { DealNewDealForm } from './DrawerNewDeal.interface';
import { useCreateNewContractMutation } from './hooks/useNewContractMutation';
import { useNewDealMutation } from './hooks/useNewDealMutation';
import { StepKeys, stepSchemas } from './schema/StepSchema';
import {
  DealConfig,
  DealDescription,
  DealDetails,
  DealPaymentType,
} from './steps';

interface Step {
  current: boolean;
  complete: boolean;
  title: string;
  description: string;
}

interface DrawerNewDealProps {
  customer: Customer;
}

export const DrawerNewDeal = ({ customer }: DrawerNewDealProps) => {
  const organizationId = localStorage.getItem('organization') || '';
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();
  const [steps, setSteps] = useState<Step[]>(DrawerSteps);
  const [indexStep, setIndexStep] = useState(0);

  const currentStepSchema = stepSchemas[indexStep as StepKeys];

  const formMethods = useForm<DealNewDealForm>({
    defaultValues: {
      configDeal: [],
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(currentStepSchema as any),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    trigger,
    formState: { isValid },
  } = formMethods;

  const handleNext = () => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => {
        if (index <= indexStep) {
          return { ...step, complete: true };
        }
        if (index === indexStep + 1) {
          return { ...step, current: true };
        }
        return step;
      })
    );
    setIndexStep((prevIndex) => prevIndex + 1);
  };

  const handleBack = () => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => {
        if (index < indexStep - 1) {
          return { ...step, complete: true };
        }
        if (index === indexStep - 1) {
          return { ...step, current: true, complete: false };
        }
        if (index === indexStep) {
          return { ...step, current: false };
        }
        return step;
      })
    );
    setIndexStep((prevIndex) => prevIndex - 1);
  };

  const handleNextStep = async () => {
    const isStepValid = await trigger(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      StepsField[indexStep as StepKeys] as any
    );
    if (isStepValid) {
      handleNext();
    }
  };

  const {
    mutate: createNewContractMutate,
    isPending: isPendingCreateNewContract,
    customError: customErrorCreateNewContract,
  } = useCreateNewContractMutation();

  const {
    mutate: createNewDealMutate,
    isPending: isPendingCreateNewDeal,
    customError: customErrorCreateNewDeal,
  } = useNewDealMutation();

  const createPayloadNewContract = (data: DealNewDealForm) => {
    const endAt = data.endAt ? dayjs(data.endAt).format('YYYY-MM-DD') : null;

    return {
      parties: [
        {
          id: customer.id,
          type: ContractPartyType.BUYER,
          entityType: 'CUSTOMER',
        },
      ],
      startAt: dayjs(data.startAt).format('YYYY-MM-DD'),
      description: data.description,
      ...(endAt && { endAt: endAt }),
    };
  };

  const createPayloadNewDeal = (data: DealNewDealForm) => {
    const paymentMethods = [
      data.recurringPix ? PaymentMethod.PIX_INITIATION : null,
      data.pix ? PaymentMethod.PIX : null,
      data.bankSlip ? PaymentMethod.BANK_SLIP : null,
    ].filter(Boolean) as PaymentMethod[];

    const secondaryPaymentMethods =
      data.recurringPix &&
      ([
        data.secondaryBankSlip ? PaymentMethod.BANK_SLIP : null,
        data.secondaryPix ? PaymentMethod.PIX : null,
      ].filter(Boolean) as PaymentMethod[]);

    return {
      startAt: data.startAt,
      description: data.description,
      products: data.configDeal,
      paymentMethods: paymentMethods,
      closingAt: `0 0 ${data.closingAt} * *`,
      dueAt: `0 0 ${data.dueAt} * *`,
      ...(data.recurringPix && {
        secondaryPaymentMethods: secondaryPaymentMethods,
      }),
      ...(data.recurringPix && {
        settings: {
          createAllInvoicesOnStart: true,
        },
      }),
      ...(data.endAt && { endAt: data.endAt }),
      expiresAt: `0 0 ${data.dueAt} * *`,
      creditParties: [
        {
          id: organizationId,
          entityType: 'ORGANIZATION',
          unit: PaymentUnit.PERCENTAGE,
          amount: 100,
        },
      ],
      debitParties: [
        {
          id: customer.id,
          entityType: 'CUSTOMER',
          unit: PaymentUnit.PERCENTAGE,
          amount: 100,
        },
      ],
    };
  };

  const onSubmit = async (data: DealNewDealForm) => {
    createNewContractMutate(createPayloadNewContract(data), {
      onSuccess: (response) => {
        createNewDealMutate({
          contractId: response.id,
          payload: createPayloadNewDeal(data),
        });
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Sidebar
        title={t('general.new-deal')}
        icon={ICONS.billing}
        open
        onClose={closeDrawer}
      >
        {(customErrorCreateNewContract || customErrorCreateNewDeal) && (
          <ContextualError
            error={customErrorCreateNewContract || customErrorCreateNewDeal}
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="newDeal flex flex-col gap-8">
            <StepByStep steps={steps} />

            {indexStep === 0 && <DealDescription />}

            {indexStep === 1 && <DealPaymentType />}

            {indexStep === 2 && <DealConfig />}

            {indexStep === 3 && <DealDetails customer={customer} />}

            <SidebarFooter>
              <Button
                type="button"
                size="large"
                variant="tertiary"
                onClick={handleBack}
                disabled={indexStep === 0}
              >
                {t('general.button.back')}
              </Button>

              {indexStep !== steps.length - 1 && (
                <Button
                  type="button"
                  size="large"
                  onClick={handleNextStep}
                  disabled={!isValid}
                >
                  {t('general.next')}
                </Button>
              )}

              {indexStep === steps.length - 1 && (
                <Button
                  type="submit"
                  size="large"
                  isLoading={
                    isPendingCreateNewContract || isPendingCreateNewDeal
                  }
                >
                  {t('button.finish')}
                </Button>
              )}
            </SidebarFooter>
          </div>
        </form>
      </Sidebar>
    </FormProvider>
  );
};
